<template>
    <div class="loading-spinner">
        <div v-for="n in 3" :key="n" :class="`bounce bounce--${n}`"></div>
    </div>
</template>

<style lang="postcss" scoped>
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    & .bounce {
        width: var(--loading-spinner-size, 1rem);
        height: var(--loading-spinner-size, 1rem);
        border-radius: 100%;
        animation: bounce-delay 1.4s infinite ease-in-out both;
        background-color: var(--loading-spinner-color, white);
    }

    & .bounce--1 {
        animation-delay: -0.32s;
    }

    & .bounce--2 {
        animation-delay: -0.16s;
    }
}

@keyframes bounce-delay {
    0%,
    80%,
    100% {
        transform: scale3d(0, 0, 0);
    }

    40% {
        transform: scale3d(1, 1, 1);
    }
}
</style>
